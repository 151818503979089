// Pre-Loader
.sd_loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include square(100%);
    @include flex;
    background: rgba(0, 0, 0, 0.15);
    z-index: 998;
    svg {
        margin: auto;
        position: relative;
        z-index: 999;
    }
}
.main-loader {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 10px #00000080;
    height: 150px;
    // left: 45%;
    // margin-left: -95px;
    padding-top: 5px;
    position: relative !important;
    top: 50%;
    width: 230px;
    z-index: 50;
    @include bp(tab) {
        width: 40vw;
        height: 27vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 1.2vw;
    }
    .loading {
        @include bp(tab) {
            position: relative;
            width: 23.666667vw;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: auto;
            font-size: 3.2vw;
            gap: 1.2vw;
        }
        li {
            text-align: center;
        }
        img {
            height: 100px;
            width: 100px;
            margin: 0 auto;
            object-fit: contain;
            @include bp(tab) {
                width: 100%;
                height: 13.9vw;
                object-fit: contain;
            }
        }
    }
}
.loader_top {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    @include bp(mini) {
        position: fixed;
    }
    &.betloader {
        position: fixed;
    }
}