$white: #fff;
$lightWhite: #f2f2f2;
$black: #000;
$gray: #5f5f5f;
$grayExtra: #c6c6c6;
$orange_btn: #ff8311;
$grayLight: #9b9b9b;
$grayDark: #6a6a6a;
$yellow: #b19526;
$headingbg: #ebebeb;
$blue: #52bce9;
$blueDark: #0089d7;
$orange_light: #ff9534;
$sonicSilver: #757575;
$silver: #c4c4c4;
$whisper: #ececec;
$gainsboro: #dedede;
$inputBg: #eeeeee;
$labelText: #4f4f4f;
$labelText2: #7a7a7a;
$bgpurple: #7758b3;
$activemenu: #acacac;
$error2: #ff5036;
$greenbg: #198754;
$text: #555555;
$text2: #929292;
$text3: #7a7a7a;
$inputbg: #e9e9e9;
$activeBg: #ffcc09;
$disable: #cdcdcd;
$lightgray: #969696;
$btnbg: #f5f5f5;
$lightPink: #ff624b;
$bglight: #f3f3f3;
$lightText: #a0a0a0;
$tabbg: #dedede;
$creditheader: #c4c4c4;
$screenbg: #848484;
$spBg: #d8d8d8;
$lightgreen: #e5f3be;
$creenText: #949494;
$fpgbtn: #6b6b6b;
$fpgbtnactive: #494949;
$fpglightbg: #f4f4f4;
$btndark: #d8d8d8;
$fpglabelbtn: #8b8b8b;
$tablehead: #626262;
$catbg: #ececec;
$filebg: #d5d5d5;
$disablerow: #e9d2d2;
$fieldbgorg: #ff8b49;
$qabg: #ff9b96;
$linkblue: #0081c9;
$linkblue_text: #00a3ff;
$error: #ff0000;
$linkblue_text: #00a3ff;
$lightOrange: #d9837e;
$btnBlack: #222222;
$orange: #ffcc2f;

$menucolor1: #ffcc2f;
$menucolor2: #ffb80c;
$menuactivecolor1: #9c9c9c8e;
$menuactivecolor2: #dcdcdc46;
$menufontcolor:#222;
